<template>
  <el-form
    ref="form"
    :model="fieldsResult"
    @keyup.enter.native="submit"
  >
    <el-form-item
      v-for="(field, i) in fields"
      :key="i"
      :prop="field"
      :rules="fieldsOptions[field].required ? rules.required : null"
      :error="validateErrors[field]"
    >
      <label
        v-if="isShowSelect(field)"
        class="main-label width-100"
      >
        <span class="main-label-form__title">{{ fieldsOptions[field].name }}</span>

        <el-select
          v-model="fieldsResult[field]"
          :multiple="fieldsOptions[field].type === 'select-multi'"
          class="width-100"
          placeholder="Выбирите"
          @change="clearErrorField(field)"
        >
          <el-option
            v-for="item in fieldsOptions[field].selectItems"
            :key="item.value"
            :label="item[fieldsOptions[field].itemText]"
            :value="item[fieldsOptions[field].itemValue]"
          />
        </el-select>
      </label>

      <label
        v-else-if="fieldsOptions[field].type === 'select-search'"
        class="main-label width-100"
      >
        <span class="main-label-form__title">{{ fieldsOptions[field].name }}</span>

        <el-select
          v-model="fieldsResult[field]"
          :multiple="fieldsOptions[field].type === 'select-multi'"
          filterable
          collapse-tags
          class="width-100"
          placeholder="Выбирите"
          @change="clearErrorField(field)"
        >
          <el-option
            v-for="item in fieldsOptions[field].selectItems"
            :key="item.value"
            :label="item[fieldsOptions[field].itemText]"
            :value="item[fieldsOptions[field].itemValue]"
          />
        </el-select>
      </label>

      <label
        v-else
        class="main-label width-100"
      >
        <span class="main-label-form__title">{{ fieldsOptions[field].name }}</span>

        <el-input
          v-model="fieldsResult[field]"
          :label="fieldsOptions[field].name"
          :type="fieldsOptions[field].type"
          :placeholder="fieldsOptions[field].name"
          @change="clearErrorField(field)"
        />
      </label>
    </el-form-item>

    <div class="block-buttons block-buttons__right">
      <el-button
        class="el-button-h-40"
        type="warning"
        plain
        @click="closeModal(null)"
      >
        Закрыть
      </el-button>

      <el-button
        class="el-button-h-40"
        type="primary"
        plain
        @click="submit"
      >
        Создать
      </el-button>
    </div>
  </el-form>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'Create-modal',
  props: {
    propsData: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      fields: Object.keys(this.propsData.fields || {}),
      fieldsOptions: this.propsData.fields || {},
      fieldsResult: {},
      validateErrors: {},
      rules: {
        required: {
          required: true,
          message: 'Поле не должно быть пустым',
          trigger: 'submit'
        }
      }
    }
  },
  created () {
    this.fields.forEach(field => {
      this.$set(this.fieldsResult, field, this.fieldsOptions[field]?.value || '')
      this.$set(this.validateErrors, field, null)
    })
  },
  methods: {
    ...mapActions('modal', ['closeModal']),
    isShowSelect (field) {
      return this.fieldsOptions[field].type === 'select' || this.fieldsOptions[field].type === 'select-multi'
    },
    async submit () {
      this.$refs.form.validate(async (valid, obj) => {
        if (!valid) {
          // Костыль. Необходим для корректного вывода ошибок
          // Из за того что поля в форме рендерятся динамически
          // появляется проблема с вывыодом ошибок
          Object.keys(obj).forEach((field) => {
            this.validateErrors[field] = obj[field]?.[0]?.message
          })

          return
        }

        if (this.propsData.page === 'workingShift') {
          await this.$store.dispatch('workingShift/open')

          return
        }

        await this.$bus.emit('start-loader')
        await this.$store.dispatch(`${this.propsData.page}/create`, this.fieldsResult)
        await this.propsData?.callback?.()
        await this.$bus.emit('stop-loader')
      })
    },
    clearErrorField (field) {
      this.validateErrors[field] = null
    }
  }
}
</script>

<style lang="scss" src="./style.scss" />
